export const xRayDensityGlandMap = {
  A: 'Практически полностью жировой тип строения',
  B: 'Рассеянные участки фиброгландулярной ткани',
  C: 'Гетерогенно плотные молочные железы. Плотные участки фиброгландулярной ткани могут скрывать мелкие образования',
  D: 'Очень плотные молочные железы. Чувствительность маммографии снижена',
};

export const mapMgQuality = {
  GOOD: 'Приемлемое качество',
  BAD: 'Плохое качество изображения',
  INCORRECT: 'Неправильная укладка',
};

export const biradsToRiskCancerMap = {
  М0т: '-',
  М0д: '-',
  М1: '0%',
  М2: '0%',
  М3: '0% < x ≤ 2%',
  М4А: '2% < x ≤ 10%',
  М4В: '10% < x ≤ 50%',
  М4С: '50% < x < 95%',
  М5: 'x ≥ 95%',
  М6: '-',
};

export const biradsMap = {
  М0т: {
    label: 'Требуется дообследование',
    levelOfThread: 1,
  },

  М0д: {
    label: 'Требуется углубленное дообследование',
    levelOfThread: 2,
  },

  М1: {
    label: 'Изменений не выявлено',
    levelOfThread: 3,
  },

  М2: {
    label: 'Доброкачественные изменения',
    levelOfThread: 4,
  },

  М3: {
    label: 'Вероятно доброкачественные изменения',
    levelOfThread: 5,
  },

  М4: {
    label: 'Признаки, вызывающие подозрение на злокачественность',
    levelOfThread: 6,
  },

  М5: {
    label: 'Практически достоверные признаки злокачественности',
    levelOfThread: 7,
  },

  М6: {
    label: 'Верифицированный РМЖ',
    levelOfThread: 8,
  },
};

const defaultMouseBindings = {
  Primary: 'Zoom',
  Auxiliary: 'Pan',
  Secondary: 'WindowLevel',
};

export function getMouseBindings() {
  const storedMouseBindings = localStorage.getItem('userMouseBindings');
  return storedMouseBindings
    ? (JSON.parse(storedMouseBindings) as typeof defaultMouseBindings)
    : defaultMouseBindings;
}

export const mgPosMap = {
  'R-CC': 'L-CC',
  'L-CC': 'R-CC',
  'R-MLO': 'L-MLO',
  'L-MLO': 'R-MLO',
};

export const genderCodeToLabelMap = {
  '3': { value: 'MALE', label: 'Мужской' },
  '2': { value: 'FEMALE', label: 'Женский' },
} as const;
